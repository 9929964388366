<template>
  <div>
    <header>
      <ConnectWallet />
    </header>

    <div class="z-0">
      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl mt-10">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="">
              <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <div class="mt-10 col-span-2">
                  <h3
                    class="text-3xl font-bold lg:text-4xl xl:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                  >
                    AI photo and video enhancer, in one click
                  </h3>

                  <h3
                    class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                  >
                    PromptDue's photo and video enhancer helps you improve,
                    sharpen and unblur your media files instantly.
                  </h3>

                  <div class="mt-5">
                    <h3
                      class="text-2xl font-bold xl:text-3xl text-white text-shadow-white"
                    >
                      Credits: {{ getUser.credits_recovery }}
                    </h3>
                  </div>

                  <input
                    type="file"
                    @change="uploadImage($event)"
                    accept="image/*"
                    id="recfile"
                    name="recfile"
                    class="text-white mt-3"
                  />

                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-200 to-gray-300 mt-2"
                  >
                    Please update image
                  </h3>

                  <br />
                </div>
              </div>

              <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <div class="flex flex-col items-center">
                  <div class="">
                    <img
                      src="/img/example/before.png"
                      class="w-full rounded-3xl"
                      v-if="_id == null"
                    />

                    <img
                      :src="
                        API_URL +
                        '/images/uploads/' +
                        data_by_id[0].url_recovery
                      "
                      class="w-full rounded-3xl"
                      v-else
                    />

                    <div class="flex flex-col items-center mt-1">
                      <a v-if="_id == null" class="text-xs text-gray-200"
                        >Example By PromptDue
                      </a>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col items-center">
                  <div class="">
                    <img
                      src="/img/example/after.png"
                      class="w-full rounded-3xl"
                      v-if="_id == null"
                    />

                    <img
                      :src="
                        API_URL + '/images/remini/' + data_by_id[0].url_recovery
                      "
                      class="w-full rounded-3xl"
                      v-else
                    />

                    <div class="flex flex-col items-center mt-1">
                      <a v-if="_id == null" class="text-xs text-gray-200"
                        >Example By PromptDue
                      </a>
                      <div class="flex">
                        <a
                          href="/buyCredit"
                          class="cursor-pointer inline-block mt-5 text-sm px-2 py-1 sm:px-4 sm:py-2 border rounded text-yellow-50 border-none mr-2 bg-gradient-to-r from-purple-600 to-pink-600"
                        >
                          <i class="fas fa-cart-arrow-down mr-1"></i>Buy Credits
                        </a>

                        <div
                          v-if="_id == null"
                          @click="
                            downloadImg(
                              'https://cdn.discordapp.com/attachments/1087056859791376417/1123996881782067360/after_1.png'
                            )
                          "
                          class="cursor-pointer inline-block mt-5 text-sm px-2 py-1 sm:px-4 sm:py-2 border rounded text-yellow-50 border-none mr-2 bg-gradient-to-r from-blue-600 to-green-600"
                        >
                          <i class="far fa-hand-point-down mr-1"></i>Download
                        </div>

                        <div
                          v-else
                          @click="downloadImg(data_by_id[0].url_recovery)"
                          class="cursor-pointer inline-block mt-5 text-sm px-2 py-1 sm:px-4 sm:py-2 border rounded text-yellow-50 border-none mr-2 bg-gradient-to-r from-blue-600 to-green-600"
                        >
                          <i class="far fa-hand-point-down mr-1"></i>Download
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl"
          v-if="imgRecovery_list.length > 0"
        >
          <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
            <div class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
              <div
                class="text-gray-200 mt-10"
                v-if="imgRecovery_list.length > 0"
              >
                <h3
                  class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 via-pink-100 to-blue-100"
                >
                  Image Recovery
                </h3>

                <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />

                <div
                  class="grid grid-cols-2 gap-3 lg:grid-cols-4 md:grid-cols-3"
                >
                  <div
                    class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer rounded-sm rounded"
                    style="margin-top: 10px"
                    v-for="s in imgRecovery_list"
                    :key="s"
                  >
                    <div
                      class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded"
                    >
                      <div class="text-sm border-none rounded bg-gray-900">
                        <a :href="'/imgRecovery/' + s._id" class="">
                          <img
                            :src="API_URL + '/images/uploads/' + s.url_recovery"
                            alt="Image 1"
                            class="inset-0 object-cover ease-in-out opacity-100 hover:opacity-0 h-44 md:h-48 lg:h-80 w-full"
                          />

                          <img
                            :src="API_URL + '/images/remini/' + s.url_recovery"
                            alt="Image 2"
                            class="absolute inset-0 object-cover transition duration-500 ease-in-out opacity-0 hover:opacity-100 h-44 md:h-48 lg:h-80 w-full pl-0.5 pr-0.5 mt-0.5"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        class="flex flex-col items-center justify-start w-full text-white p-3 mb-28"
      >
        <div
          class="py-4 space-y-6 md:py-8 lg:py-12 max-w-2xl w-full -mt-5 h-screen flex items-center justify-center"
        >
          <div>
            <div class="w-full flex justify-center">
              <img v-if="preview_img" :src="preview_img" class="w-1/2" />
            </div>

            <div class="max-w-xl mt-5 p-3">
              <h2 class="text-xl lg:text-4xl font-bold text-white">
                AI photo and video enhancer, in one click
              </h2>

              <h2 class="text-sm lg:text-xl font-bold text-white">
                Remini's photo and video enhancer helps you improve, sharpen and
                unblur your media files instantly.
              </h2>
            </div>

            <div
              class="flex w-full p-3 pb-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg"
            >
              <div class="py-4 space-y-6 md:py-8 lg:py-2 w-full text-gray-300">
                <div
                  class="w-full p-px bg-gradient-to-r from-blue-700 via-gray-300 to-green-500 rounded-3xl shadow-xl"
                >
                  <div
                    class="flex flex-col h-full w-full bg-dark-900 p-6 overflow-y-auto border-none rounded-3xl bg-gray-900 bg-opacity-75"
                  >
                    <div class="flex flex-col justify-center space-y-3">
                      <div class="bg-gray-800 py-2 px-3 rounded">
                        <div class="text-base font-medium">
                          <i class="fad fa-wallet mr-2 z-0"></i>

                          <input
                            type="file"
                            @change="uploadImage($event)"
                            accept="image/*"
                            id="recfile"
                            name="recfile"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mt-2">
                      <span class="text-gray-300">Value(USD)</span>

                      <div class="flex flex-col sm:flex-row gap-2 mt-2">
                        <div
                          class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-blue-200"
                        >
                          <i class="fad fa-copy mr-1 text-blue-200"></i>
                          Transactions
                        </div>

                        <div
                          class="text-baseline whitespace-nowrap text-blue opacity-80 hover:opacity-100 focus:opacity-100 items-center text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-blue-200 mr-3"
                        >
                          <i class="fad fa-clipboard-list mr-1"></i> Withdrawal
                          history
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div>
        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";
import { mapGetters, mapActions } from "vuex";

import ImgRecovery from "@/api/imgRecovery";

import axios from "axios";

import { API_URL } from "../../config";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      event: null,
      dataKYC: {},
      API_URL: API_URL,
      preview_img: null,
      purchasd: [],
      imgRecovery_list: [],
      _id: this.$route.params._id || null,
      data_by_id: {},
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  computed: mapGetters(["getBalance", "getCurrency", "getUser"]),

  methods: {
    ...mapActions(["setUser"]),

    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
    async uploadImage(event) {
      this.event = event;

      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview_img = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }

      if (parseFloat(this.getUser.credits_recovery) > 0) {
        this.c();
      }
    },

    c(event) {
      const URL = `${API_URL}/prompt/img/create`;
      let data = new FormData();
      data.append("file", this.event.target.files[0]);
      data.append("address", this.getUser.address);
      axios.post(URL, data).then((response) => {
        console.log("image upload response > ", response);
        this.setUser(response.data);
      });

      setTimeout(() => {
        this.getDataImage();
      }, 4000);

      setTimeout(() => {
        this.getDataImage();
      }, 6000);

      setTimeout(() => {
        this.getDataImage();
      }, 8000);

      let timerInterval;
      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 6000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log("I was closed by the timer");
        }
      });
    },
    async downloadImg(url) {
      const imageUrl = API_URL + "/images/remini/" + url; // Replace with the actual image URL

      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Create a temporary anchor element
          const a = document.createElement("a");
          const url = URL.createObjectURL(blob);

          // Set the anchor element's href and download attributes
          a.href = url;
          a.download = "image.jpg"; // Specify the file name for the downloaded image

          // Programmatically click the anchor element to trigger the download
          a.click();

          // Clean up the temporary anchor element and URL object
          a.remove();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    async getDataImage() {
      await ImgRecovery.imgList({
        address: this.getUser.address,
      })
        .then((res) => {
          this.imgRecovery_list = res.data.data;

          if (this._id != null) {
            var data_by_id = res.data.data.filter((datas) => {
              return datas._id == this._id;
            });
            this.data_by_id = data_by_id;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getDataImage();
  },
};
</script>
